import React, { Component } from "react";

class Npsnew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.default
    };
  }

  handleRadioChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    this.setState({ value: selectedValue });
    this.props.onChange(selectedValue);
  };

  getBoxColor = (number) => {
    const colorMapping = {
      0: "#FF0000",
      1: "#FF0000",
      2: "#FF0000",
      3: "#FF2424",
      4: "#FF5151",
      5: "#FF8000",
      6: "#FF9933",
      7: "#A0A0A0",
      8: "#404040",
      9: "#009900",
      10: "#006600"
    };

    return colorMapping[number] || "gray";
  };

  renderNpsnewBoxes = () => {
    const { min, max } = this.props;
    const { value } = this.state;
    const boxes = [];

    for (let i = min; i <= max; i++) {
      const containerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "5px",
        cursor: "pointer"
      };

      const boxStyle = {
        backgroundColor: this.getBoxColor(i),
        width: "40px",
        height: "40px",
        marginBottom: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // borderRadius: "5px"
      };

      const numberStyle = {
        color: "white",
        fontWeight: "bold"
      };

      boxes.push(
        <label key={i} style={containerStyle}>
          <div style={boxStyle}>
            <span style={numberStyle}>{i}</span>
          </div>
          <input
            type="radio"
            value={i}
            checked={value === i}
            onChange={this.handleRadioChange}
          />
        </label>
      );
    }
    return boxes;
  };

  render() {
    return (
      <div style={{ overflowX: "auto" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.renderNpsnewBoxes()}
        </div>
      </div>
    );
  }
}

export default Npsnew;
