import React, { Component } from 'react'
import axios from 'axios'
import logo from '../../assets/img/ff_black_logo.png'
import moment from 'moment'
import {
    Form,
    Button,
    Row,
    Col,
    Radio,
    Checkbox,
    Input,
    InputNumber,
    DatePicker,
    message,
    Select,
    Result,
    Typography,
    Rate,
    Slider
} from 'antd'

import { getBase64 } from '../generics/utils'

import styles from './classic.module.scss'
import ProgressiveImage from 'react-progressive-graceful-image'
import Npsnew from './Npsnew'

class Classic extends Component {
    constructor(props) {
        super(props)
        this.state = {
            answers: [],
            want_reason: {},
            loading: false,
            submitted: false,
            feedbackerFields: null,
            feedbacker: null,
            billInfoFields: null,
            billInfo: null,
            nps: 8,

            active_question:0
        }
    }

    componentDidMount() {
        let answers = []

        const { form } = this.props
        form.all_questions.map(question => {
            let answer = {
                "question": question.id,
                "reason": "",
                "checklist_image": "",
                "reason_image": "",
                "options": [],
                "text": "",
            }
            answers.push(answer)
        })
        let feedbackerFields = []
        let billInfoFields = []
        let feedbacker = {}
        let billInfo = {}
        if (form.has_customer_question) {
            Object.keys(form.customer_info_settings).map(key => {
                if (form.customer_info_settings[key] !== 0) {
                    let field = {
                        label: form.customer_info_labels[`label_${key}`],
                        required: form.customer_info_settings[key] === 2 ? true : false,
                        key: key,
                    }
                    feedbackerFields.push(field)
                }
                if (key === 'date_of_birth') {
                    feedbacker['dob'] = ''
                }
                else if (key === 'date_of_anniversary') {
                    feedbacker['anni'] = ''
                }
                else if (key === 'name') {
                    feedbacker['first_name'] = ''
                    feedbacker['last_name'] = ''
                }
                else {
                    feedbacker[key] = ''
                }
            })
        }
        if (form.has_bill_info_question) {
            Object.keys(form.bill_info_settings).map(key => {
                if (form.bill_info_settings[key] !== 0) {
                    let field = {
                        label: form.bill_info_labels[`label_${key}`],
                        required: form.bill_info_settings[key] === 2 ? true : false,
                        key: key,
                    }
                    billInfoFields.push(field)
                }
                billInfo[key] = ''
            })

        }
        this.setState({
            answers,
            feedbackerFields,
            feedbacker,
            billInfoFields,
            billInfo,
        })

        document.getElementById(`div-${this.state.active_question}`).style.opacity = 1
        document.getElementById(`div-${this.state.active_question}`).style.cursor = 'initial'
        document.getElementById(`div-${this.state.active_question}`).style.margin = '9rem 0'
        document.getElementById(`answer-${this.state.active_question}`).style.pointerEvents = 'all'

    }

    onFinish = (values) => {
        const { answers, feedbacker, billInfo, nps } = this.state
        const { store, form } = this.props
        const dash = atob(store.dash_id)

        this.setState({ loading: true })
        const data = {
            "is_task": false,
            "is_task_verified": false,
            "otp_sent": false,
            "otp_verified": false,
            "start_time": moment().unix(),
            "nps": nps,
            "refer": {
                "name": " "
            },
            "comment": "",
            "timestamp": moment().unix(),
            "bill": billInfo.bill,
            "amount": billInfo.amount,
            "table": billInfo.table,
            "remarks": "",
            "feedback_form": form.id,
            "feedback_taker": {
                "pin": dash,
            },
            "feedbacker": feedbacker,
            "answers": answers
        }

        axios.post(
            `${process.env.REACT_APP_API_URL}/feedbacks/apiv2/feedback-api/`,
            data
        )
            .then(() => {
                message.success('SUCCESS')
                this.setState({ loading: false, submitted: true })
            })
            .catch(() => {
                message.error('ERROR, Try again.')
                this.setState({ loading: false })
            })
    }



    handleImageUpload = async (event, question) => {
        const imageUrl = await getBase64(event.target.files[0])
        this.handleAnswerChange(question, null, { checklist_image: String(imageUrl) })
    }

    handleAnswerChange = (question, option_id, itemAttributes) => {
        const { answers, want_reason } = this.state
        var index = answers.findIndex(x => x.question === question.id)
        if (index === -1) {
            return message.error('Error')
        }
        else {
            this.setState({
                answers: [
                    ...answers.slice(0, index),
                    Object.assign({}, answers[index], itemAttributes),
                    ...answers.slice(index + 1)
                ]
            })
        }
        // if (option_id.length) {

        // }
        if (option_id && !option_id.length) {
            const { options } = question
            let optIndex = options.findIndex(x => x.id === option_id)
            const selectedOption = options[optIndex]
            if (selectedOption.want_reason) {
                this.setState({
                    want_reason: {
                        ...want_reason,
                        [question.id]: true
                    }
                })
            }
            else {
                this.setState({
                    want_reason: {
                        ...want_reason,
                        [question.id]: false
                    }
                })
            }
        }

        

        
          console.log("yo")
        this.setState({active_question: this.state.active_question});

        if(this.state.active_question !== 0){
            document.getElementById(`div-${this.state.active_question}`).style.opacity = 0.5
            document.getElementById(`answer-${this.state.active_question}`).style.pointerEvents = 'none'
            document.getElementById(`div-${this.state.active_question}`).style.cursor = 'pointer'
            document.getElementById(`div-${this.state.active_question}`).style.margin = '3rem 0'
        } else {
            document.getElementById(`div-0`).style.opacity = 0.5
            document.getElementById(`answer-0`).style.pointerEvents = 'none'
            document.getElementById(`div-0`).style.cursor = 'pointer'
            document.getElementById(`div-0`).style.margin = '3rem 0'


        }
        document.getElementById(`div-${this.state.active_question}`).scrollIntoView({
            behavior: 'smooth',
            inline:'center',
            block: 'center'
        });
        document.getElementById(`div-${this.state.active_question}`).style.opacity = 1
        document.getElementById(`div-${this.state.active_question}`).style.margin = '9rem 0'
        document.getElementById(`div-${this.state.active_question}`).style.cursor = 'initial'
        document.getElementById(`answer-${this.state.active_question}`).style.pointerEvents = 'all'



    }

    nextChar = (c) => {
        return String.fromCharCode(c.charCodeAt(0) + 1);
    }


    handleRating = (value, question) => {
        console.log(question , value)
        if (question && value) {
            const selectedOption = question.options[value - 1].id

            this.setState({ [question.id]: true })
            console.log('working')

            this.handleAnswerChange(
                question,
                selectedOption, {
                options: [parseInt(selectedOption)],
                text: String(question.options[value - 1].score)
            }
            )
        }
    }

    renderOptions = question => {
        const { form } = this.props

        if (question.type === 1) {
            return (
                <>
                    <Form.Item
                        name="mcq"
                        rules={[
                            {
                                required: question.compulsary_question,
                                message: "This field is required."
                            }
                        ]}
                    >
                        <Radio.Group
                            className={styles.w100}
                            onChange={
                                event => this.handleAnswerChange(question, event.target.value, { options: [parseInt(event.target.value)] })
                            }
                            buttonStyle="solid"
                        >
                            <Row>
                                {
                                    question.options.map(option => (
                                        <Col md={12} xs={24}>
                                            <Radio.Button
                                                value={option.id}
                                                className={styles.radioButtons}
                                            >
                                                <span
                                                    className={styles.optionTitle}
                                                >
                                                    {option.title}
                                                </span>
                                            </Radio.Button>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Radio.Group>
                    </Form.Item>
                    {
                        this.state.want_reason[question.id]
                            ?
                            <Form.Item
                                name={"reason" + String(question.id)}
                            >
                                <Input
                                    onChange={
                                        event => {
                                            this.handleAnswerChange(question, null, { reason: event.target.value })
                                        }
                                    }
                                    placeholder="Type your Reason"
                                />
                            </Form.Item>
                            : ''
                    }
                </>
            )
        }
        else if (question.type === 3) {
            return (
                <>
                    <Form.Item
                    // name="rating"
                    // rules={[
                    //     {
                    //         required: question.compulsary_question,
                    //         message: "This field is required."
                    //     }
                    // ]}
                    // noStyle={true}
                    >
                        {/* <Radio.Group
                            className={styles.w100}
                            onChange={
                                event => this.handleAnswerChange(question, event.target.value, { options: [parseInt(event.target.value)] })
                            }
                            buttonStyle="solid"
                        > */}
                        <Row>
                            {/* {
                                    question.options.map(option => (
                                        <Radio.Button
                                            value={option.id}
                                            danger={option.score < 3 ? true : false}

                                        >
                                            <span className={styles.optionTitle}>{option.title}</span>
                                        </Radio.Button>
                                    ))
                                } */}
                            {/* <Input value={this.state[question.id] ? this.state[question.id] : null} hidden={true} /> */}
                            <Rate
                                style={{ fontSize: '2.5rem' }}
                                onChange={
                                    value => this.handleRating(value, question)
                                }
                            />
                        </Row>
                        {/* </Radio.Group> */}
                    </Form.Item>
                    {
                        this.state.want_reason[question.id]
                            ?
                            <Form.Item
                                name={"reason" + String(question.id)}
                            >
                                <Input
                                    onChange={
                                        event => {
                                            this.handleAnswerChange(question, null, { reason: event.target.value })
                                        }
                                    }
                                    placeholder="Type your Reason"
                                />
                            </Form.Item>
                            : ''
                    }
                </>
            )
        }
        else if (question.type === 7) {
            return (
                <>
                    <Form.Item
                        name="yes/no"
                        rules={[
                            {
                                required: question.compulsary_question,
                                message: "This field is required."
                            }
                        ]}>
                        <Radio.Group
                            className={styles.w100}
                            onChange={
                                event => this.handleAnswerChange(question, event.target.value, { options: [parseInt(event.target.value)] })
                            }
                            buttonStyle="solid"
                        >
                            <Row>
                                {
                                    question.options.map(option => (
                                        <Col span={12}>
                                            <Radio.Button
                                                value={option.id}
                                                danger={option.score < 3 ? true : false}
                                                className={styles.radioButtons}
                                            >
                                                <span className={styles.optionTitle}>{option.title}</span>
                                            </Radio.Button>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Radio.Group>
                    </Form.Item>
                    {
                        this.state.want_reason[question.id]
                            ?
                            <Form.Item
                                name={"reason" + String(question.id)}
                            >
                                <Input
                                    onChange={
                                        event => {
                                            this.handleAnswerChange(question, null, { reason: event.target.value })
                                        }
                                    }
                                    placeholder="Type your Reason"
                                />
                            </Form.Item>
                            : ''
                    }
                </>
            )
        }
        else if (question.type === 8) {
            return (
                <>
                    <Form.Item
                        name="mcq_non_rating"
                        rules={[
                            {
                                required: question.compulsary_question,
                                message: "This field is required."
                            }
                        ]}
                    >
                        <Radio.Group
                            className={styles.w100}
                            onChange={event => this.handleAnswerChange(question, event.target.value, { options: [parseInt(event.target.value)] })}
                            buttonStyle="solid"
                        >
                            <Row>
                                {
                                    question.options.map(option => (
                                        <Col md={12} xs={24}>
                                            <Radio.Button
                                                value={option.id}
                                                className={styles.radioButtons}
                                            >
                                                <span className={styles.optionTitle}>{option.title}</span>
                                            </Radio.Button>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Radio.Group>
                    </Form.Item>
                    {
                        this.state.want_reason[question.id]
                            ?
                            <Form.Item
                                name={"reason" + String(question.id)}
                            >
                                <Input
                                    onChange={
                                        event => {
                                            this.handleAnswerChange(question, null, { reason: event.target.value })
                                        }
                                    }
                                    placeholder="Type your Reason"
                                />
                            </Form.Item>
                            : ''
                    }
                </>
            )
        }
        else if (question.type === 9) {
            return (
                <>
                    <Form.Item
                        name="dropdown"
                        rules={[
                            {
                                required: question.compulsary_question,
                                message: "This field is required."
                            }
                        ]}
                    >
                        <Row>
                            <Col span={12}>
                                <Select
                                    onChange={value => this.handleAnswerChange(question, value, { options: [value] })}
                                    style={{ width: 300 }}
                                    placeholder="Select an option"
                                >
                                    {
                                        question.options.map(option => (
                                            <Select.Option value={option.id}><span className={styles.optionTitle}>{option.title}</span></Select.Option>
                                        ))
                                    }
                                </Select>
                            </Col>
                        </Row>
                    </Form.Item>
                    {
                        this.state.want_reason[question.id]
                            ?
                            <Form.Item
                                name={"reason" + String(question.id)}
                            >
                                <Input
                                    onChange={
                                        event => {
                                            this.handleAnswerChange(question, null, { reason: event.target.value })
                                        }
                                    }
                                    placeholder="Type your Reason"
                                />
                            </Form.Item>
                            : ''
                    }
                </>
            )
        }
        else if (question.type === 10) {
            return (
                <>
                    <Form.Item
                        name="checkbox"
                        rules={[
                            {
                                required: question.compulsary_question,
                                message: "This field is required."
                            }
                        ]}
                    >
                        <Checkbox.Group
                            className={styles.w100}
                            onChange={checkedList => this.handleAnswerChange(question, checkedList, { options: checkedList })}
                        >
                            <Row>
                                {
                                    question.options.map(option => (
                                        <Col span={12}>
                                            <Checkbox value={option.id}><span className={styles.optionTitle}>{option.title}</span></Checkbox>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                    {
                        this.state.want_reason[question.id]
                            ?
                            <Form.Item
                                name={"reason" + String(question.id)}
                            >
                                <Input
                                    onChange={
                                        event => {
                                            this.handleAnswerChange(question, null, { reason: event.target.value })
                                        }
                                    }
                                    placeholder="Type your Reason"
                                />
                            </Form.Item>
                            : ''
                    }
                </>
            )
        }
        else if (question.type === 11) {
            return (
                <Form.Item
                    name="single_image_select"
                    rules={[
                        {
                            required: question.compulsary_question,
                            message: "This field is required."
                        }
                    ]}
                >
                    <Radio.Group
                        className={styles.w100}
                        onChange={event => this.handleAnswerChange(question, event.target.value, { options: [parseInt(event.target.value)] })}
                        buttonStyle="solid"
                        size="large"
                    >
                        <Row className={styles.imageOptionRow}>
                            {
                                question.options.map(option => (
                                    <Col span={12}>
                                        <Radio.Button
                                            value={option.id}
                                            className={`${styles.radioButtons} ${styles.imageOptions}`}
                                        >
                                            <span className={styles.optionTitle}>
                                                {/* <img src={option.image} width="200px" alt={option.title} /> */}
                                                <ProgressiveImage src={option.image} placeholder={option.image}>
                                                    {(src) => <img src={src} alt={option.title} width="200px" />}
                                                </ProgressiveImage>
                                            </span>
                                        </Radio.Button>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Radio.Group>
                </Form.Item>
            )
        }
        else if (question.type === 12) {
            return question.options.map(option =>
                <p>MULTIPLE IMAGE - <span className={styles.optionTitle}>{option.title}</span></p>
            )
        }
        else {
            return (
                <>
                    <Form.Item
                        name="mics"
                        rules={[
                            {
                                required: question.compulsary_question,
                                message: "This field is required."
                            }
                        ]}                >
                        <Radio.Group
                            className={styles.w100}> {/* onChange={event => this.handleAnswerChange(question, event.target.value, { options: [parseInt(event.target.value)] })}>*/}
                            <Row>
                                {
                                    question.options.map(option => (
                                        <Radio.Button
                                            value={option.id}
                                            danger={option.score < 3 ? true : false}
                                            className={styles.radioButtons}
                                        >
                                            <span className={styles.optionTitle}>{option.title}</span>
                                        </Radio.Button>
                                    ))
                                }
                            </Row>
                        </Radio.Group>
                    </Form.Item>
                    {
                        this.state.want_reason[question.id]
                            ?
                            <Form.Item
                                name={"reason" + String(question.id)}
                            >
                                <Input
                                    onChange={
                                        event => {
                                            this.handleAnswerChange(question, null, { reason: event.target.value })
                                        }
                                    }
                                    placeholder="Type your Reason"
                                />
                            </Form.Item>
                            : ''
                    }
                </>
            )
        }
    }

    renderNonOptions = question => {
        if (question.type === 4) {
            return (
                <Form.Item
                    name="char"
                    rules={[
                        {
                            required: question.compulsary_question,
                            message: "This field is required."
                        }
                    ]}
                >
                    <Input
                        placeholder="Type your Answer"
                        onChange={event => this.handleAnswerChange(question, null, { text: event.target.value })}
                    />
                </Form.Item>
            )
        }
        else if (question.type === 5) {
            return (
                <Form.Item
                    name="text"
                    rules={[
                        {
                            required: question.compulsary_question,
                            message: "This field is required."
                        }
                    ]}
                >
                    <Input.TextArea
                        placeholder="Type your Answer"
                        onChange={event => this.handleAnswerChange(question, null, { text: event.target.value })}
                    />
                </Form.Item>
            )
        }
        else if (question.type === 6) {
            return (
                <Form.Item
                    name="number"
                    rules={[
                        {
                            required: question.compulsary_question,
                            message: "This field is required."
                        }
                    ]}
                >
                    <InputNumber
                        placeholder="Type your Answer"
                        onChange={value => this.handleAnswerChange(question, null, { text: String(value) })}
                    />
                </Form.Item>
            )
        }
        else if (question.type === 13) {
            return (
                <Form.Item
                    name="date"
                    rules={[
                        {
                            required: question.compulsary_question,
                            message: "This field is required."
                        }
                    ]}
                >
                    <DatePicker
                        format="MM/DD/YYYY"
                        onChange={(date, dateString) => this.handleAnswerChange(question, null, { text: dateString })}
                    />
                </Form.Item>
            )
        }
        else if (question.type === 15) {
            return (
                // <Form.Item
                //     rules={[
                //         {
                //             required: question.compulsary_question,
                //             message: "This field is required."
                //         }
                //     ]}
                //     noStyle={true}
                // >
                <input onChange={(e) => this.handleImageUpload(e, question)} type="file" alt="input" accept="image/*" />
                // </Form.Item>
            )
        }
        else if (question.type === 16) {
            return (
                <Form.Item
                    name="date-range"
                    rules={[
                        {
                            required: question.compulsary_question,
                            message: "This field is required."
                        }
                    ]}
                >
                    <DatePicker.RangePicker
                        onChange={(value, dateArray) => this.handleAnswerChange(question, null, { text: `(${dateArray[0]}, ${dateArray[1]})` })}
                    />
                </Form.Item>
            )
        }
        else if (question.type === 17) {
            const { feedbackerFields, feedbacker } = this.state

            const handleFeedbackerChange = (key, value) => {
                if (key === 'date_of_birth') {
                    key = 'dob'
                }
                if (key === 'date_of_anniversary') {
                    key = 'anni'
                }
                if (key === 'name') {
                    const first_name = value.substr(0, value.indexOf(' '))
                    const last_name = value.substr(value.indexOf(' ') + 1)
                    this.setState({
                        feedbacker: { ...feedbacker, first_name, last_name },
                    })
                    return
                }
                this.setState({
                    feedbacker: { ...feedbacker, [key]: value }
                })
            }

            return (
                feedbackerFields
                    ?
                    <>
                        {feedbackerFields.map(field => {
                            if (field.key === 'date_of_anniversary' || field.key === 'date_of_birth') {
                                return (
                                    <>
                                        <Form.Item
                                            name={field.key}
                                            labelCol={{ span: 5 }}
                                            rules={[
                                                {
                                                    required: field.required,
                                                    message: 'This field is required.'
                                                }
                                            ]}
                                        >
                                            <DatePicker
                                                className={styles.customInput}
                                                placeholder={`${field.label}${field.required ? '*' : ''}`}
                                                format="MM/DD/YYYY"
                                                onChange={(date, dateString) => handleFeedbackerChange(field.key, dateString)}
                                            />
                                        </Form.Item>
                                    </>
                                )
                            }
                            else if (field.key === 'email') {
                                return (
                                    <>
                                        <Form.Item
                                            name={field.key}
                                            labelCol={{ span: 5 }}
                                            type="email"
                                            rules={[
                                                {
                                                    type: 'email',
                                                    message: 'Please type your correct email address.'
                                                },
                                                {
                                                    required: field.required,
                                                    message: 'Please type your email address.'
                                                }
                                            ]}
                                        >
                                            <Input
                                                className={styles.input}
                                                placeholder={`${field.label}${field.required ? '*' : ''}`}
                                                onChange={(event) => handleFeedbackerChange(field.key, event.target.value)}
                                            />
                                        </Form.Item>
                                    </>
                                )
                            }
                            else {
                                return (
                                    <>
                                        <Form.Item
                                            name={field.key}
                                            labelCol={{ span: 5 }}
                                            rules={[
                                                {
                                                    required: field.required,
                                                    message: 'This field is required.'
                                                }
                                            ]}
                                        >
                                            <Input
                                                className={styles.input}
                                                placeholder={`${field.label}${field.required ? '*' : ''}`}
                                                onChange={(event) => handleFeedbackerChange(field.key, event.target.value)}
                                            />
                                        </Form.Item>
                                    </>
                                )
                            }
                        })}
                    </>
                    : 'NA'
            )
        }
        else if (question.type === 18) {
            const { billInfoFields, billInfo } = this.state

            const handleBillInfoChange = (key, value) => {
                this.setState({
                    billInfo: { ...billInfo, [key]: value }
                })
            }

            return (
                billInfoFields
                    ?
                    <>
                        {billInfoFields.map(field => {
                            if (field.key === 'amount') {
                                return (
                                    <>
                                        <Form.Item
                                            name={field.key}
                                            labelCol={{ span: 5 }}
                                            rules={[
                                                {
                                                    required: field.required,
                                                    message: 'This field is required.'
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                className={styles.input}
                                                placeholder={`${field.label}${field.required ? '*' : ''}`}
                                                onChange={(value) => handleBillInfoChange(field.key, value)}
                                            />
                                        </Form.Item>
                                    </>
                                )
                            }
                            else {
                                return (
                                    <>
                                        <Form.Item
                                            name={field.key}
                                            labelCol={{ span: 5 }}
                                            rules={[
                                                {
                                                    required: field.required,
                                                    message: 'This field is required.'
                                                }
                                            ]}
                                        >
                                            <Input
                                                className={styles.input}
                                                placeholder={`${field.label}${field.required ? '*' : ''}`}
                                                onChange={(event) => handleBillInfoChange(field.key, event.target.value)}
                                            />
                                        </Form.Item>
                                    </>
                                )
                            }
                        })}
                    </>
                    : 'NA'
            )
        }
        else if (question.type === 19) {
            const marks = {
                0: 0,
                10: 10,
            }
            const { nps } = this.state

            return (
                <Form.Item
                    name="date-range"
                    rules={[
                        {
                            required: question.compulsary_question,
                            message: "This field is required."
                        }
                    ]}
                >
                    {/* <Slider
                        min={0}
                        max={10}
                        defaultValue={nps}
                        className={styles.npsSlider}
                        tooltipVisible
                        marks={marks}
                        onChange={value => this.setState({ nps: value })}
                    /> */}
                    <Npsnew
                    min={0}
                    max={10}
                    onChange={(value) => this.setState({ nps: value })}
                    // onChange={(value) => console.log(`Selected value: ${value}`)}
                    
                    />
                </Form.Item>
            )
        }
        else if (question.type === 20) {
            return (
                <>
                    <p
                    // style={{ color: form.screen_message.text_color }}
                    >
                        {question.text}
                    </p>

                    {question.q_image && (
                        <img
                            src={question.q_image}
                            alt={question.title}
                            style={{
                                maxHeight: '300px',
                                maxWidth: 'auto',
                            }}
                        />
                    )}
                </>
            )
        }
    }

    handleActiveQuestion = (index) => {
        document.getElementById(`div-${this.state.active_question}`).style.opacity = 0.5
        document.getElementById(`div-${this.state.active_question}`).style.cursor = 'pointer'
        document.getElementById(`answer-${this.state.active_question}`).style.pointerEvents = 'none'
        document.getElementById(`div-${this.state.active_question}`).style.margin = '3rem 0'

        this.setState({active_question: index});
            document.getElementById(`div-${this.state.active_question}`).style.opacity = 1
            document.getElementById(`div-${this.state.active_question}`).style.cursor = 'initial'
            document.getElementById(`answer-${this.state.active_question}`).style.pointerEvents = 'all'
            document.getElementById(`div-${this.state.active_question}`).style.margin = '9rem 0'
            document.getElementById(`div-${this.state.active_question}`).scrollIntoView({
                behavior: 'smooth',
                inline:'center',
                block: 'center'
            });
    }

    renderQuestions = questions => {
        

        return questions.map((question, index) => {
            return (
                <div onClick={() => this.handleActiveQuestion(index)} style={{opacity: 0.5, cursor:'pointer', margin: index == 0 ? '3rem 0 9rem 0' : '3rem 0'}} id={`div-${index}`} className={styles.questionContainer}>
                    <h2 style={{ color: this.props.form.screen_message.text_color }}>{index + 1}. {question.title}</h2>
                    <div style={{pointerEvents: 'none'}} id={`answer-${index}`}>
                        {
                            question.if_option ?
                                this.renderOptions(question)
                                :
                                this.renderNonOptions(question)
                        }
                    </div>
                    
                </div>
            )
        })
    }

    render() {
        const { form, store } = this.props
        const { loading, submitted } = this.state

        return (
            <div className={styles.bodyContainer} style={{
                backgroundColor: form.screen_message.background_color,
                color: form.screen_message.text_color,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                marginTop:-32,
                paddingTop:32,

                backgroundImage:form.screen_message.darken_image ? 
                `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${form.screen_message.background_image}")`
                 : form.screen_message.background_image ? 
                 `url("${form.screen_message.background_image}")` :
                  ""
                // height: '100vh'
                
            }}>
                <div className={styles.container}>
                    {
                        submitted
                            ?
                            <Result
                                status="success"
                                title={
                                    form.screen_message.thankyou_message
                                        ?
                                        form.screen_message.thankyou_message
                                        :
                                        "Thank you for your valuable feedback. It means lot to us."
                                }
                                subTitle={
                                    <>
                                        <div>
                                            <Button
                                                type="primary"
                                                size="large"
                                                onClick={() => {
                                                    window.location.reload();
                                                }}
                                            >
                                                Start a New Response
                                            </Button>
                                        </div>
                                    </>
                                }
                            />
                            :
                            <>
                                {
                                    form.screen_message.welcome_message
                                        ?
                                        <Typography.Title style={{ color: form.screen_message.text_color }} level={2}>{form.screen_message.welcome_message}</Typography.Title>
                                        :
                                        <Typography.Title style={{ color: form.screen_message.text_color }} level={2}>Please fill this survey to help us serve you better</Typography.Title>
                                }

                                {
                                    form.all_questions.length > 0
                                        ?
                                        <Form
                                            scrollToFirstError={true}
                                            onFinish={this.onFinish}
                                            onFinishFailed={() => {
                                                message.error('There are some required fields which needs to filled.')
                                            }}
                                        >
                                            {this.renderQuestions(form.all_questions)}
                                            <Form.Item
                                                className={styles.buttonContainer}
                                            >
                                                <Button
                                                    loading={loading}
                                                    type="primary"
                                                    htmlType="submit"
                                                    className={styles.submitButton}
                                                    size="large"
                                                    style={{
                                                        backgroundColor: form.screen_message.button_bg_color,
                                                        color: form.screen_message.button_text_color,
                                                        border: `1px solid ${form.screen_message.button_bg_color}`
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                        : ''
                                }
                            </>

                    }
                    <div className={styles.banner}>
                        {
                            store && store.store_logo
                                ?
                                <>
                                    <p>Powered by</p>
                                    <a href="https://fellafeeds.com">
                                        <img className={styles.logo} src={store.store_logo} alt={store.name} />
                                    </a>
                                </>
                                :
                                <>
                                    <p>Powered by</p>
                                    <a href="https://fellafeeds.com">
                                        <img className={styles.logo} src={logo} alt="Fellafeeds" />
                                    </a>
                                </>

                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Classic