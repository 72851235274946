import React, { Component } from 'react'
import { connect } from 'react-redux';
// import GlobalLayout from './generics/layout'
import Templates from './Templates';

class Index extends Component {
    // constructor(props) {
    //     super(props)
    //     setTimeout(() => {
    //         if (this.props.isAuthenticated) {
    //             this.props.history.push('/feedback-forms/')
    //         }
    //     }, 500);
    // }

    componentDidMount() {

    }

    render() {
        return (
            <Templates />
        )
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps,)(Index)