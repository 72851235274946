import React, { Component } from "react";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { response } from "../../actions";
import { connect } from "react-redux";
import Classic from "./Classic";
import OneQuestionAtTime from "./OneQuestionAtTime";

import styles from "./classic.module.scss";

class Response extends Component {
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //       showThankyouScreen: false,
  //     };
  //   }
  componentDidMount() {
    if (this.props.type === "template") {
      this.props.getTemplateFeedbackForm(this.props.templateID);
    } else {
      let str = this.props.match.params.slug;
      const getNumber = str.replace(/^\D+/g, "");
      const feedbackId = this.props.match.params.fid;

      let queryParams = {
        feedback_form: getNumber,
      };
      if (feedbackId) {
        queryParams["feedback"] = feedbackId;
      }
      this.props.getFeedbackForm(queryParams).then((res) => {
        // if (
        //   res.status === 220 &&
        //   res.data.message === "feedback_already_completed"
        // ) {
        //   this.setState({ showThankyouScreen: true });
        // }
      });
    }
  }

  renderForm = (feedbackForm, store, feedbacker, forceThankyouScreen, npsAction) => {
    if (feedbackForm.form_format === 2) {
      return (
        <Classic form={feedbackForm} store={store} feedbacker={feedbacker} />
      );
    } else if (feedbackForm.form_format === 3) {
      return (
        <OneQuestionAtTime
          form={feedbackForm}
          store={store}
          feedbacker={feedbacker}
          forceThankyouScreen={forceThankyouScreen}
          npsAction={npsAction}
        />
      );
    } else {
      return <h1>Not here</h1>;
    }
  };

  render() {
    console.log("npsAction", this.props)
    const { feedbackForm, store, feedbacker, forceThankyouScreen, npsAction } = this.props;
    const loadingIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;

    return (
      <>
        {feedbackForm ? (
          this.renderForm(feedbackForm, store, feedbacker, forceThankyouScreen, npsAction)
        ) : (
          <div className={styles.loaderScreen}>
            <Spin indicator={loadingIcon} />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  feedbackForm: state.response.feedbackForm,
  store: state.response.store,
  feedbacker: state.response.feedbacker,
  forceThankyouScreen: state.response.forceThankyouScreen,
  npsAction: state.response.npsAction,
});

const mapDispatchToProps = (dispatch) => ({
  getFeedbackForm: (data) => dispatch(response.getFormForResponse(data)),
  getTemplateFeedbackForm: (data) =>
    dispatch(response.getTemplateFeedbackForm(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Response);
