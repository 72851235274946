/**
 * For Multiple menu selected 
 */

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: { 
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};


export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    });
}

export const formatString = (string) => {
    return string.toUpperCase().replace(/_/g, ' ')
}

export const truncate = (str, length, ending) => {

    if (length == null) {
        length = 100
    }
    if (ending == null) {
        ending = '...'
    }
    if (str && str.length > length) {
        return str.substring(0, length - ending.length) + ending
    } else {
        return str
    }
}

export const mergeArrays = (...arrays) => {
    let jointArray = []

    arrays.forEach(array => {
        jointArray = [...jointArray, ...array]
    });
    return Array.from(new Set([...jointArray]))
}