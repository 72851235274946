import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Typography, Menu, Layout, Row, Col, Button, message } from 'antd'
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'
// import ProgressiveImage from 'react-progressive-graceful-image'

import Preloader from '../generics/preloader'

import GlobalLayout from '../generics/layout'
import styles from './templates.module.scss'
import { templates } from '../../actions'
import Response from '../Response'
import CardOfTemplates from './CardOfTemplates'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'


class Templates extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: null,
            templates: null,
            previewScreen: false,
            selectedTemplate: null,
            selectedCategory: null,
            loading: false,
        }
    }

    componentDidMount() {
        this.props.getAllTemplates()
            .then(() => {
                const allTemplates = this.props.allTemplates
                let categories = [
                    {
                        name: 'All Templates',
                        slug: 'all'
                    }
                ]
                allTemplates.map(t => {
                    let category = {}
                    category['name'] = t.name
                    category['slug'] = t.slug
                    categories.push(category)
                })
                this.setState({
                    categories,
                    selectedCategory: this.props.match.params.slug ? this.props.match.params.slug : 'all'
                })
            })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ loading: true })

        if (nextProps.match.params.slug && (nextProps.match.params.slug !== this.state.selectedCategory)) {
            const key = nextProps.match.params.slug
            this.setState({ loading: true })
            this.props.getTemplatesOfACategory(key)
                .then(() => {
                    this.setState({
                        selectedCategory: key,
                        loading: false
                    })
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    })
                })
        } else {

            this.setState({ selectedCategory: nextProps.match.params.slug ? nextProps.match.params.slug : 'all', loading: false })
            if (nextProps.match.params.slug === 'undefined') {
                this.props.history.push('/feedback-form-templates/')
            }
        }
    }

    handleCategoryChange = (key) => {
        const { history } = this.props

        if (key === 'all') {
            history.push(`/feedback-form-templates/`,)
            // window.location.replace('/feedback-form-templates/')
        }
        else {
            history.push(`/feedback-form-templates/c/${key}-feedback-form-templates/`)
        }
    }

    renderAllTemplates = () => {
        const { allTemplates, location } = this.props
        let base_path = location.pathname
        if (base_path[base_path.length - 1] !== '/') {
            base_path = base_path + '/'
        }

        return (
            allTemplates
                ?
                allTemplates.map(category => (
                    category.templates.length > 0 ?
                        <div className={styles.allTemplates}>
                            <Link to={`${base_path}c/${category.slug}-feedback-form-templates/`}>
                                <div className={`space-between ${styles.templateHeader}`}>
                                    <Typography.Title
                                        level={4}
                                        type="secondary"
                                        className={`title-case ${styles.title}`}
                                    >
                                        {category.name} Feedback Form Templates ({category.total_templates})
                                </Typography.Title>
                                    <p>See All <ArrowRightOutlined /></p>
                                </div>
                            </Link>
                            <CardOfTemplates templates={category.templates} />
                        </div>
                        : ''
                ))
                : ''
        )
    }

    renderSelectedCategoryTemplate = () => {
        const { categoryTemplates } = this.props
        const { loading } = this.state

        if (categoryTemplates && !loading) {
            return (
                <>
                    <Typography.Title
                        level={4}
                        type="secondary"
                        className={`title-case ${styles.title}`}
                    >
                        {categoryTemplates.name} Feedback Form Templates ({categoryTemplates.templates.length})
                    </Typography.Title>
                    <CardOfTemplates templates={categoryTemplates.templates} />
                </>
            )
        } else {
            return (
                <Preloader />
            )
        }
    }

    handleUseThisTemplate = (template_id) => {
        this.setState({ loading: true })

        const { isAuthenticated, createFormFromTemplate, formCreatedViaTemplate } = this.props

        if (isAuthenticated) {
            createFormFromTemplate({ 'template_id': template_id })
                .then(() => {
                    this.setState({ loading: false })
                    this.props.history.push(`/feedback-forms/${formCreatedViaTemplate.id}/`)
                })
                .catch(error => {
                    this.setState({ loading: false })
                    if (error.response) {
                        message.error(error.response.data.message)
                    } else {
                        message.error(error.message)
                    }
                })
        }
    }

    renderPreviewScreen = () => {
        const { selectedTemplate, loading } = this.state

        if (selectedTemplate) {
            return (
                <div>
                    <div className='space-between styles.stickyToolbar'>
                        <Button
                            onClick={() => this.setState({ previewScreen: false })}
                        >
                            <ArrowLeftOutlined /> Back to Templates
                        </Button>
                        <Button
                            onClick={() => this.handleUseThisTemplate(selectedTemplate.id)}
                            type="primary"
                            loading={loading}
                        >
                            Use this Template for Free
                    </Button>
                    </div>
                    <Response
                        type="template"
                        templateID={selectedTemplate.id}
                    />
                </div>
            )
        }
        else {
            return (
                <div>
                    Kindly select a template
                </div>
            )
        }
    }


    render() {
        // const bgImage = "https://imagebucket-fella.s3.amazonaws.com/static/images/pages/forms/salon-forms-main.png"
        const { categories, selectedCategory, previewScreen, loading } = this.state
        const { Sider, Content } = Layout

        return (
            <GlobalLayout
                title="Free Feedback Form Templates | Use on Tab, Web &amp; Android | Fellafeeds"
                description="Feedback form for different types and industry, forms for different interface, feedback for restaurant , student, Coaching, 
                hotel, can be taken on iphone, Android, Web, Kiosk etc"
                sidebar={false}
                headerNotFixed={true}
            >
                <div className={styles.container}>
                    <Row className={styles.header}>
                        <Col md={12}>
                            <div className={styles.textContent}>
                                <span className={styles.title}>Feedback Form Templates</span>
                                <p>
                                    Create Customizable Surveys to collect Customer Feedback efficiently through Net Promoter Score and CSAT which will assist in making crucial business decisions for the growth of the brand.
                                </p>
                            </div>
                        </Col>
                        <Col md={12}>

                        </Col>
                        {/* <div styles={styles.imageContent}>

                        </div> */}
                    </Row>
                    {
                        !previewScreen ?
                            categories
                                ?
                                <Layout>
                                    <Sider
                                        className={styles.sider}
                                        style={{
                                            position: 'sticky',
                                            top: 0,
                                            left: 0,
                                            borderRight: '1px solid #f0f0f0'
                                        }}
                                    >
                                        <Menu
                                            mode="inline"
                                            style={{ width: 256 }}
                                            defaultSelectedKeys={[selectedCategory]}
                                            selectedKeys={[selectedCategory]}
                                            onSelect={({ key }) => this.handleCategoryChange(key)}
                                        >
                                            {
                                                categories.map(category => (
                                                    <Menu.Item key={category.slug} disabled={loading}>
                                                        <span className="title-case">{category.name}</span>
                                                    </Menu.Item>
                                                ))
                                            }
                                        </Menu>
                                    </Sider>
                                    <Content className={styles.content}>
                                        {
                                            selectedCategory === 'all'
                                                ?
                                                this.renderAllTemplates()
                                                :
                                                this.renderSelectedCategoryTemplate()
                                        }
                                    </Content>
                                </Layout>
                                : <Preloader />
                            : this.renderPreviewScreen()
                    }
                </div>
            </GlobalLayout>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated,
    allTemplates: state.templates.allTemplates,
    categoryTemplates: state.templates.categoryTemplates,
    formCreatedViaTemplate: state.templates.formCreatedViaTemplate,
})

const mapDispatchToProps = dispatch => ({
    getAllTemplates: () => dispatch(templates.getAllFeedbackFormTemplates()),
    getTemplatesOfACategory: (data) => dispatch(templates.getTemplatesOfACategory(data)),
    createFormFromTemplate: (data) => dispatch(templates.createFormFromTemplate(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Templates))