import React from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col, Card } from 'antd'

import { Card as CardMob } from 'antd-mobile'

import styles from './templates.module.scss'
import { Link } from 'react-router-dom'
import ProgressiveImage from 'react-progressive-graceful-image'
import { truncate } from '../generics/utils'

import 'antd-mobile/dist/antd-mobile.css';

const CardOfTemplates = ({ templates, location }) => {
    let base_path = location.pathname
    if (base_path[base_path.length - 1] !== '/') {
        base_path = base_path + '/'
    }
    return (
        <Row className={styles.allTemplates} gutter={[16]}>
            {
                templates.map((template, index) => {
                    return (
                        <Col key={index} lg={6} md={8} sm={24} xs={24}>
                            <Link to={`/feedback-form-templates/${template.slug}/`}>
                                {
                                    window.innerWidth < 720
                                        ?
                                        <>
                                            <CardMob className={styles.cardMob} full>
                                                <CardMob.Body>
                                                    <div className={styles.cardMobBody}>
                                                        <Row style={{ width: '100%' }}>
                                                            <Col span={8}>
                                                                <ProgressiveImage src={template.form_image} placeholder={template.form_image}>
                                                                    {(src, loading) => (
                                                                        <img style={{ opacity: loading ? 0.4 : 1, minHeight: '80px', maxHeight: '80px', minWidth: '120px', maxWidth: '120px' }} src={src} alt={template.title} />
                                                                    )}
                                                                </ProgressiveImage>
                                                            </Col>
                                                            <Col span={16}>
                                                                <div className={styles.cardMobText}>
                                                                    <div className={`title-case ${styles.cardMobTitle}`}>{template.title}</div>
                                                                    <div className={styles.cardMobDescription}>{truncate(template.description, 60)}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </CardMob.Body>
                                                {/* <CardMob.Footer content="footer content" extra={<div>extra footer content</div>} /> */}
                                            </CardMob>
                                        </>
                                        :
                                        <Card
                                            cover={
                                                <ProgressiveImage src={template.form_image} placeholder={template.form_image}>
                                                    {(src, loading) => (
                                                        <img style={{ opacity: loading ? 0.1 : 1, minHeight: '150px', maxHeight: '200px' }} src={src} alt={template.title} />
                                                    )}
                                                </ProgressiveImage>
                                            }
                                            hoverable={true}
                                            className={styles.card}
                                        >
                                            <Card.Meta
                                                title={<span className="title-case">{template.title}</span>}
                                                description={truncate(template.description, 90)}
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            />
                                        </Card>
                                }
                            </Link>
                        </Col>
                    )
                })
            }
        </Row>
    )
}

export default withRouter(CardOfTemplates)