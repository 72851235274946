import React, { Component, createRef } from "react";
import moment from "moment";
import axios from "axios";

import Npsnew from "./Npsnew";

import {
  Form,
  Button,
  Row,
  Col,
  Radio,
  Checkbox,
  Input,
  InputNumber,
  DatePicker,
  message,
  Select,
  Result,
  Typography,
  Rate,
} from "antd";

import {
  UpOutlined,
  DownOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import ProgressiveImage from "react-progressive-graceful-image";
import styled from "styled-components";
import Bounce from "react-reveal/Bounce";

import logo from "../../assets/img/ff_black_logo.png";
import styles from "./oneQuestion.module.scss";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

class OneQuestionAtTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screens: [],
      welcome: true,
      answers: [],
      want_reason: {},
      submitted: false,
      loading: false,
      feedbackerFields: null,
      feedbacker: null,
      billInfoFields: null,
      billInfo: null,
      nps: 8,
      forceShowSubmit: false,
    };
  }

  formRef = createRef();

  componentDidMount() {
    const { form, forceThankyouScreen } = this.props;

    if (forceThankyouScreen) {
      this.setState({ submitted: true, welcome: false });
    }

    let answers = [];
    form.all_questions.map((question, index) => {
      if (index === 0) {
        question["display"] = true;
      } else {
        question["display"] = false;
      }
      let answer = {
        question: question.id,
        reason: "",
        checklist_image: "",
        reason_image: "",
        options: [],
        text: "",
      };
      answers.push(answer);
    });

    let feedbackerFields = [];
    let billInfoFields = [];
    let feedbacker = {};
    const propsFeedbacker = this.props.feedbacker || {};
    let billInfo = {};
    if (form.has_customer_question) {
      Object.keys(form.customer_info_settings).map((key) => {
        if (form.customer_info_settings[key] !== 0) {
          let field = {
            label: form.customer_info_labels[`label_${key}`],
            required: form.customer_info_settings[key] === 2 ? true : false,
            key: key,
            disabled: Boolean(propsFeedbacker[key]) || false,
          };
          feedbackerFields.push(field);
        }
        if (key === "date_of_birth") {
          feedbacker["dob"] = propsFeedbacker["dob"] || "";
        } else if (key === "date_of_anniversary") {
          feedbacker["anni"] = propsFeedbacker["anni"] || "";
        } else if (key === "name") {
          feedbacker["first_name"] = propsFeedbacker["first_name"] || "";
          feedbacker["last_name"] = propsFeedbacker["last_name"] || "";
          feedbacker["name"] = propsFeedbacker["full_name"] || "";
        } else {
          feedbacker[key] = propsFeedbacker[key] || "";
        }
      });
    }
    if (form.has_bill_info_question) {
      const searchParams = new URLSearchParams(this.props.location.search);

      Object.keys(form.bill_info_settings).map((key) => {
        if (form.bill_info_settings[key] !== 0) {
          let field = {
            label: form.bill_info_labels[`label_${key}`],
            required: form.bill_info_settings[key] === 2 ? true : false,
            key: key,
            disabled: Boolean(searchParams.get(key)) || false,
          };
          billInfoFields.push(field);
        }
        billInfo[key] = searchParams.get(key) || "";
      });
    }

    this.setState({
      screens: form.all_questions,
      answers,
      feedbackerFields,
      feedbacker,
      billInfoFields,
      billInfo,
    });
  }

  // use this for pre-populating
  componentDidUpdate(props) {
    const { current } = this.formRef;
    if (current === null) return;

    const { setFieldsValue } = current;
    let newValues = {};
    if (props.feedbacker && props.form.has_customer_question) {
      this.state.feedbackerFields.map((field) => {
        if (props.feedbacker[field.key]) {
          newValues[field.key] = props.feedbacker[field.key];
        }
        if (field.key === "name") {
          newValues["name"] = props.feedbacker["full_name"];
        }
      });
    }

    if (this.state.billInfo && props.form.has_bill_info_question) {
      this.state.billInfoFields.map((field) => {
        if (this.state.billInfo[field.key]) {
          newValues[field.key] = this.state.billInfo[field.key];
        }
      });
    }
    setFieldsValue(newValues);
  }

  updateScreens = () => {};

  hide = (currentIndex) => {
    const { screens } = this.state;

    this.setState({
      screens: [
        ...screens.slice(0, currentIndex),
        Object.assign({}, screens[currentIndex], { display: false }),
        ...screens.slice(currentIndex + 1),
      ],
    });
  };

  show = (nextIndex) => {
    const { screens } = this.state;

    this.setState({
      screens: [
        ...screens.slice(0, nextIndex),
        Object.assign({}, screens[nextIndex], { display: true }),
        ...screens.slice(nextIndex + 1),
      ],
    });
  };

  handleNext = async (current, next) => {
    const { screens } = this.state;
    const currentIndex = screens.findIndex((x) => x.id === current);
    let nextIndex = currentIndex + 1;
    if (next) {
      nextIndex = screens.findIndex((x) => x.id === next);
    }

    if (nextIndex < screens.length) {
      this.formRef.current.validateFields().then(async () => {
        await this.hide(currentIndex);
        await this.show(nextIndex);
      });
    }
  };

  AButton = (screen_message) => {
    const AButton = styled(Button)`
      height: 3rem;
      padding: 10px;
      background-color: ${screen_message.button_bg_color};
      color: ${screen_message.button_text_color};
      cursor: pointer;
      border: 1px solid ${screen_message.button_bg_color};
      letter-spacing: 1px;
      border-radius: 3px;
      font-weight: 700;
      font-size: 20px;
      &:hover {
        background-color: ${screen_message.button_bg_color};
        color: ${screen_message.button_text_color};
        border: 1px solid ${screen_message.button_bg_color};
      }
      &:active {
        background-color: ${screen_message.button_bg_color};
        color: ${screen_message.button_text_color};
        border: 1px solid ${screen_message.button_bg_color};
      }
      &:focus {
        background-color: ${screen_message.button_bg_color};
        color: ${screen_message.button_text_color};
        border: 1px solid ${screen_message.button_bg_color};
      }
    `;
    return AButton;
  };

  showNextOrSubmit = (question) => {
    const { screens, forceShowSubmit } = this.state;
    const { form } = this.props;

    const index = screens.findIndex((x) => x.id === question.id);
    const AButton = this.AButton(form.screen_message);

    return index < screens.length - 1 && !forceShowSubmit ? (
      <Form.Item>
        <AButton onClick={() => this.handleNext(question.id)} type="primary">
          Next <ArrowRightOutlined />
        </AButton>
      </Form.Item>
    ) : (
      ""
    );
  };

  handlePrevious = async (current, prev) => {
    const { screens } = this.state;
    const currentIndex = screens.findIndex((x) => x.id === current);
    let prevIndex = currentIndex - 1;
    if (prev) {
      prevIndex = screens.findIndex((x) => x.id === prev);
    }
    if (prevIndex > -1) {
      await this.hide(currentIndex);
      await this.show(prevIndex);
    }
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handleImageUpload = async (event, question) => {
    const imageUrl = await this.getBase64(event.target.files[0]);
    this.handleAnswerChange(question, null, {
      checklist_image: String(imageUrl),
    });
  };

  handleAnswerChange = (question, option_id, itemAttributes) => {
    const { answers, want_reason } = this.state;
    var index = answers.findIndex((x) => x.question === question.id);
    if (index === -1) {
      return message.error("Error");
    } else {
      this.setState({
        answers: [
          ...answers.slice(0, index),
          Object.assign({}, answers[index], itemAttributes),
          ...answers.slice(index + 1),
        ],
      });
    }

    if (option_id && !option_id.length) {
      const { options } = question;
      let optIndex = options.findIndex((x) => x.id === option_id);
      const selectedOption = options[optIndex];
      if (selectedOption.want_reason) {
        this.setState({
          want_reason: {
            ...want_reason,
            [question.id]: true,
          },
        });
      } else {
        this.setState({
          want_reason: {
            ...want_reason,
            [question.id]: false,
          },
        });
        setTimeout(() => {
          this.handleNext(question.id);
        }, 500);
      }
    }
  };

  nextChar = (c) => {
    return String.fromCharCode(c.charCodeAt(0) + 1);
  };

  handleRating = (value, question) => {
    if (question && value) {
      const selectedOptionIndex = question.options.findIndex(
        (el) => el.score === value
      );

      if (selectedOptionIndex === -1) {
        message.error("Wrong Option Selected");
        return;
      }
      const selectedOption = question.options[selectedOptionIndex];

      this.setState({ [question.id]: true });

      this.handleAnswerChange(question, selectedOption.id, {
        options: [parseInt(selectedOption.id)],
        text: String(selectedOption.score),
      });
    }
  };

  renderReasonContainer = (question) => {
    const { form } = this.props;
    const AButton = this.AButton(form.screen_message);

    return (
      <>
        <Form.Item name={"reason" + String(question.id)}>
          <Input
            className={styles.input}
            onChange={(event) => {
              this.handleAnswerChange(question, null, {
                reason: event.target.value,
              });
            }}
            placeholder="Type your reason here..."
            autoFocus
          />
        </Form.Item>
        <Form.Item>
          <AButton
            onClick={() => this.handleNext(question.id)}
            type="primary"
            htmlType="submit"
          >
            Next <ArrowRightOutlined />
          </AButton>
        </Form.Item>
      </>
    );
  };

  renderOptions = (question) => {
    const { form } = this.props;

    if (question.type === 1) {
      return (
        <>
          <Form.Item
            name="mcq"
            rules={[
              {
                required: question.compulsary_question,
                message: "This field is required.",
              },
            ]}
          >
            <Radio.Group
              className={styles.w100}
              size="large"
              onChange={(event) =>
                this.handleAnswerChange(question, event.target.value, {
                  options: [parseInt(event.target.value)],
                })
              }
              buttonStyle="solid"
            >
              <Row>
                {question.options.map((option) => (
                  <Col md={12} xs={24} key={option.id}>
                    <Radio.Button
                      value={option.id}
                      className={styles.radioButtons}
                    >
                      <span
                        className={styles.optionTitle}
                        style={{ color: form.screen_message.text_color }}
                      >
                        {option.title}
                      </span>
                    </Radio.Button>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Form.Item>
          {this.state.want_reason[question.id]
            ? this.renderReasonContainer(question)
            : ""}
        </>
      );
    } else if (question.type === 3) {
      const { answers } = this.state;

      var index = answers.findIndex((x) => x.question === question.id);
      let rateValue = parseInt(answers[index].text);

      return (
        <>
          <Form.Item
            // name="rating"
            rules={[
              {
                required: question.compulsary_question,
                message: "This field is required.",
              },
            ]}
            noStyle={true}
          >
            {/* <Radio.Group
                            className={styles.w100}
                            onChange={
                                event => this.handleAnswerChange(question, event.target.value, { options: [parseInt(event.target.value)] })
                            }
                            buttonStyle="solid"
                        > */}
            {/* <Row> */}
            {/* {
                                    question.options.map((option, index) => (
                                        <Radio.Butto key={index}n
                                            value={option.id}
                                            danger={option.score < 3 ? true : false}

                                        >
                                            <span className={styles.optionTitle}>{option.title}</span>
                                        </Radio.Button>
                                    ))
                                } */}
            {/* <Input className={styles.input} value={this.state[question.id] ? this.state[question.id] : null} hidden={true} /> */}
            <Rate
              style={{ fontSize: "2.5rem" }}
              value={rateValue}
              onChange={(value) => this.handleRating(value, question)}
            />
            {/* </Row> */}
            {/* </Radio.Group> */}
          </Form.Item>
          {this.state.want_reason[question.id]
            ? this.renderReasonContainer(question)
            : ""}
        </>
      );
    } else if (question.type === 7) {
      return (
        <>
          <Form.Item
            name="yes/no"
            rules={[
              {
                required: question.compulsary_question,
                message: "This field is required.",
              },
            ]}
          >
            <Radio.Group
              className={styles.w100}
              size="large"
              onChange={(event) =>
                this.handleAnswerChange(question, event.target.value, {
                  options: [parseInt(event.target.value)],
                })
              }
              buttonStyle="solid"
            >
              <Row>
                {question.options.map((option, index) => (
                  <Col md={12} xs={24} key={index}>
                    <Radio.Button
                      value={option.id}
                      danger={option.score < 3 ? true : false}
                      className={styles.radioButtons}
                    >
                      <span
                        className={styles.optionTitle}
                        style={{ color: form.screen_message.text_color }}
                      >
                        {option.title}
                      </span>
                    </Radio.Button>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Form.Item>
          {this.state.want_reason[question.id]
            ? this.renderReasonContainer(question)
            : ""}
        </>
      );
    } else if (question.type === 8) {
      return (
        <>
          <Form.Item
            name="mcq_non_rating"
            rules={[
              {
                required: question.compulsary_question,
                message: "This field is required.",
              },
            ]}
          >
            <Radio.Group
              className={styles.w100}
              onChange={(event) =>
                this.handleAnswerChange(question, event.target.value, {
                  options: [parseInt(event.target.value)],
                })
              }
              buttonStyle="solid"
              size="large"
            >
              <Row>
                {question.options.map((option, index) => (
                  <Col md={12} xs={24} key={index}>
                    <Radio.Button
                      value={option.id}
                      className={styles.radioButtons}
                    >
                      <span
                        className={styles.optionTitle}
                        style={{ color: form.screen_message.text_color }}
                      >
                        {option.title}
                      </span>
                    </Radio.Button>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Form.Item>
          {this.state.want_reason[question.id] ? (
            <Form.Item name={"reason" + String(question.id)}>
              <Input
                className={styles.input}
                onChange={(event) => {
                  this.handleAnswerChange(question, null, {
                    reason: event.target.value,
                  });
                }}
                placeholder="Type your reason here..."
              />
            </Form.Item>
          ) : (
            ""
          )}
        </>
      );
    } else if (question.type === 9) {
      return (
        <>
          <Form.Item
            name="dropdown"
            rules={[
              {
                required: question.compulsary_question,
                message: "This field is required.",
              },
            ]}
          >
            <Row>
              <Col span={12}>
                <Select
                  onChange={(value) =>
                    this.handleAnswerChange(question, value, {
                      options: [value],
                    })
                  }
                  style={{ width: 300 }}
                  placeholder="Select an option"
                  className={styles.customInput}
                >
                  {question.options.map((option, index) => (
                    <Select.Option value={option.id} key={index}>
                      <span className={styles.optionTitle}>{option.title}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Form.Item>
          {this.state.want_reason[question.id]
            ? this.renderReasonContainer(question)
            : ""}
        </>
      );
    } else if (question.type === 10) {
      return (
        <>
          <Form.Item
            name="checkbox"
            rules={[
              {
                required: question.compulsary_question,
                message: "This field is required.",
              },
            ]}
          >
            <Checkbox.Group
              className={styles.w100}
              onChange={(checkedList) =>
                this.handleAnswerChange(question, checkedList, {
                  options: checkedList,
                })
              }
            >
              <Row>
                {question.options.map((option, index) => (
                  <Col span={12} key={index}>
                    <Checkbox value={option.id}>
                      <span className={styles.optionTitle}>{option.title}</span>
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          {this.state.want_reason[question.id]
            ? this.renderReasonContainer(question)
            : ""}
        </>
      );
    } else if (question.type === 11) {
      return (
        <Form.Item
          name="single_image_select"
          rules={[
            {
              required: question.compulsary_question,
              message: "This field is required.",
            },
          ]}
        >
          <Radio.Group
            className={styles.w100}
            onChange={(event) =>
              this.handleAnswerChange(question, event.target.value, {
                options: [parseInt(event.target.value)],
              })
            }
            buttonStyle="solid"
            size="large"
          >
            <Row className={styles.imageOptionRow}>
              {question.options.map((option, index) => (
                <Col span={12} key={index}>
                  <Radio.Button
                    value={option.id}
                    className={`${styles.radioButtons} ${styles.imageOptions}`}
                  >
                    <span className={styles.optionTitle}>
                      {/* <img src={option.image} width="200px" alt={option.title} /> */}
                      <ProgressiveImage
                        src={option.image}
                        placeholder={option.image}
                      >
                        {(src) => (
                          <img src={src} alt={option.title} width="200px" />
                        )}
                      </ProgressiveImage>
                    </span>
                  </Radio.Button>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>
      );
    } else if (question.type === 12) {
      return question.options.map((option, index) => (
        <p key={index}>
          MULTIPLE IMAGE -{" "}
          <span className={styles.optionTitle}>{option.title}</span>
        </p>
      ));
    } else {
      return (
        <>
          <Form.Item
            name="mics"
            rules={[
              {
                required: question.compulsary_question,
                message: "This field is required.",
              },
            ]}
          >
            <Radio.Group className={styles.w100}>
              {" "}
              {/* onChange={event => this.handleAnswerChange(question, event.target.value, { options: [parseInt(event.target.value)] })}>*/}
              <Row>
                {question.options.map((option, index) => (
                  <Radio.Button
                    key={index}
                    value={option.id}
                    danger={option.score < 3 ? true : false}
                    className={styles.radioButtons}
                  >
                    <span
                      className={styles.optionTitle}
                      style={{ color: form.screen_message.text_color }}
                    >
                      {option.title}
                    </span>
                  </Radio.Button>
                ))}
              </Row>
            </Radio.Group>
          </Form.Item>
          {this.state.want_reason[question.id]
            ? this.renderReasonContainer(question)
            : ""}
        </>
      );
    }
  };

  renderNonOptions = (question) => {
    const { form } = this.props;

    if (question.type === 4) {
      return (
        <>
          <Form.Item
            name="char"
            rules={[
              {
                required: question.compulsary_question,
                message: "This field is required.",
              },
            ]}
          >
            <Input
              className={styles.input}
              style={{ color: form.screen_message.text_color }}
              placeholder="Type your answer here"
              onChange={(event) =>
                this.handleAnswerChange(question, null, {
                  text: event.target.value,
                })
              }
              autoFocus
            />
          </Form.Item>

          {this.showNextOrSubmit(question)}
        </>
      );
    } else if (question.type === 5) {
      return (
        <>
          <Form.Item
            name="text"
            rules={[
              {
                required: question.compulsary_question,
                message: "This field is required.",
              },
            ]}
          >
            <Input.TextArea
              style={{ color: form.screen_message.text_color }}
              className={styles.input}
              placeholder="Type your answer here"
              onChange={(event) =>
                this.handleAnswerChange(question, null, {
                  text: event.target.value,
                })
              }
              autoFocus
            />
          </Form.Item>

          {this.showNextOrSubmit(question)}
        </>
      );
    } else if (question.type === 6) {
      return (
        <>
          <Form.Item
            name="number"
            rules={[
              {
                required: question.compulsary_question,
                message: "This field is required.",
              },
              {
                type: "number",
                message: "This should be a number",
              },
            ]}
          >
            <InputNumber
              className={styles.input}
              style={{ color: form.screen_message.text_color }}
              placeholder="Type your answer here"
              onChange={(value) =>
                this.handleAnswerChange(question, null, { text: String(value) })
              }
              autoFocus
            />
          </Form.Item>

          {this.showNextOrSubmit(question)}
        </>
      );
    } else if (question.type === 13) {
      return (
        <>
          <Form.Item
            name="date"
            rules={[
              {
                required: question.compulsary_question,
                message: "This field is required.",
              },
            ]}
          >
            <DatePicker
              className={styles.customInput}
              style={{ color: form.screen_message.text_color }}
              format="MM/DD/YYYY"
              onChange={(date, dateString) =>
                this.handleAnswerChange(question, null, { text: dateString })
              }
            />
          </Form.Item>

          {this.showNextOrSubmit(question)}
        </>
      );
    } else if (question.type === 15) {
      return (
        // <Form.Item
        //     rules={[
        //         {
        //             required: question.compulsary_question,
        //             message: "This field is required."
        //         }
        //     ]}
        //     noStyle={true}
        // >
        <>
          <input
            onChange={(e) => this.handleImageUpload(e, question)}
            type="file"
            alt="input"
            accept="image/*"
          />

          {this.showNextOrSubmit(question)}
        </>
        // </Form.Item>
      );
    } else if (question.type === 16) {
      return (
        <>
          <Form.Item
            name="date-range"
            rules={[
              {
                required: question.compulsary_question,
                message: "This field is required.",
              },
            ]}
          >
            <DatePicker.RangePicker
              style={{ color: form.screen_message.text_color }}
              className={styles.customInput}
              onChange={(value, dateArray) =>
                this.handleAnswerChange(question, null, {
                  text: `(${dateArray[0]}, ${dateArray[1]})`,
                })
              }
            />
          </Form.Item>

          {this.showNextOrSubmit(question)}
        </>
      );
    } else if (question.type === 17) {
      const { feedbackerFields, feedbacker } = this.state;

      const handleFeedbackerChange = (key, value) => {
        if (key === "date_of_birth") {
          key = "dob";
        }
        if (key === "date_of_anniversary") {
          key = "anni";
        }
        if (key === "name") {
          const first_name = value.substr(0, value.indexOf(" "));
          const last_name = value.substr(value.indexOf(" ") + 1);
          this.setState({
            feedbacker: { ...feedbacker, first_name, last_name },
          });
          return;
        }
        this.setState({
          feedbacker: { ...feedbacker, [key]: value },
        });
      };

      return feedbackerFields ? (
        <>
          {feedbackerFields.map((field, index) => {
            if (
              field.key === "date_of_anniversary" ||
              field.key === "date_of_birth"
            ) {
              return (
                <Form.Item
                  key={index}
                  name={field.key}
                  labelCol={{ span: 5 }}
                  rules={[
                    {
                      required: field.required,
                      message: "This field is required.",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ color: form.screen_message.text_color }}
                    className={styles.customInput}
                    placeholder={`${field.label}${field.required ? "*" : ""}`}
                    format="MM/DD/YYYY"
                    onChange={(date, dateString) =>
                      handleFeedbackerChange(field.key, dateString)
                    }
                    disabled={field.disabled}
                  />
                </Form.Item>
              );
            } else if (field.key === "email") {
              return (
                <>
                  <Form.Item
                    name={field.key}
                    labelCol={{ span: 5 }}
                    type="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please type your correct email address.",
                      },
                      {
                        required: field.required,
                        message: "Please type your email address.",
                      },
                    ]}
                  >
                    <Input
                      style={{ color: form.screen_message.text_color }}
                      className={styles.input}
                      placeholder={`${field.label}${field.required ? "*" : ""}`}
                      value={feedbacker.email}
                      onChange={(event) =>
                        handleFeedbackerChange(field.key, event.target.value)
                      }
                      disabled={field.disabled}
                    />
                  </Form.Item>
                </>
              );
            } else {
              return (
                <>
                  <Form.Item
                    name={field.key}
                    labelCol={{ span: 5 }}
                    rules={[
                      {
                        required: field.required,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <Input
                      style={{ color: form.screen_message.text_color }}
                      className={styles.input}
                      placeholder={`${field.label}${field.required ? "*" : ""}`}
                      onChange={(event) =>
                        handleFeedbackerChange(field.key, event.target.value)
                      }
                      disabled={field.disabled}
                    />
                  </Form.Item>
                </>
              );
            }
          })}
          {this.showNextOrSubmit(question)}
        </>
      ) : (
        "NA"
      );
    } else if (question.type === 18) {
      const { billInfoFields, billInfo } = this.state;

      const handleBillInfoChange = (key, value) => {
        this.setState({
          billInfo: { ...billInfo, [key]: value },
        });
      };

      return billInfoFields ? (
        <>
          {billInfoFields.map((field, index) => {
            if (field.key === "amount") {
              return (
                <Form.Item
                  key={index}
                  name={field.key}
                  labelCol={{ span: 5 }}
                  rules={[
                    {
                      required: field.required,
                      message: "This field is required.",
                    },
                  ]}
                >
                  <InputNumber
                    className={styles.input}
                    style={{ color: form.screen_message.text_color }}
                    placeholder={`${field.label}${field.required ? "*" : ""}`}
                    onChange={(value) => handleBillInfoChange(field.key, value)}
                    disabled={field.disabled}
                  />
                </Form.Item>
              );
            } else {
              return (
                <>
                  <Form.Item
                    name={field.key}
                    labelCol={{ span: 5 }}
                    rules={[
                      {
                        required: field.required,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <Input
                      className={styles.input}
                      style={{ color: form.screen_message.text_color }}
                      placeholder={`${field.label}${field.required ? "*" : ""}`}
                      onChange={(event) =>
                        handleBillInfoChange(field.key, event.target.value)
                      }
                      disabled={field.disabled}
                    />
                  </Form.Item>
                </>
              );
            }
          })}
          {this.showNextOrSubmit(question)}
        </>
      ) : (
        "NA"
      );
    } else if (question.type === 19) {
      const marks = {
        0: 0,
        10: 10,
      };
      const {npsAction} = this.props;

      return (
        <Form.Item
          name="nps"
          rules={[
            {
              required: question.compulsary_question,
              message: "This field is required.",
            },
          ]}
        >
          {/* <Slider
                        min={0}
                        max={10}
                        defaultValue={nps}
                        className={styles.npsSlider}
                        tooltipVisible
                        marks={marks}
                        onChange={value => this.setState({ nps: value })}
                    /> */}
          <Npsnew
            min={0}
            max={10}
            default={this.state.nps}
            onChange={(value) => {
              let forceShowSubmit = false;
              if(npsAction && npsAction.redirect_url && npsAction.nps_range && npsAction.nps_range.length > 0 && (value >= npsAction.nps_range[0] && value <= npsAction.nps_range[1])){
                forceShowSubmit = true;
              }
              this.setState({ nps: value, forceShowSubmit })
            }}
            // onChange={(value) => console.log(`Selected value: ${value}`)}
          />
          {this.showNextOrSubmit(question, true)}
        </Form.Item>
      );
    } else if (question.type === 20) {
      return (
        <>
          <Typography.Title
            level={4}
            style={{ color: form.screen_message.text_color }}
          >
            {question.text}
          </Typography.Title>

          {question.q_image && (
            <img
              src={question.q_image}
              alt={question.title}
              style={{
                maxHeight: "60vh",
                maxWidth: "80vw",
                marginBottom: "10px",
              }}
            />
          )}
          {this.showNextOrSubmit(question)}
        </>
      );
    }
  };

  handleSubmit = () => {
    const { answers, feedbacker, billInfo, nps } = this.state;
    const { store, form } = this.props;
    const dash = atob(store.dash_id);

    this.setState({ loading: true });
    let data = {
      is_task: false,
      is_task_verified: false,
      otp_sent: false,
      otp_verified: false,
      start_time: moment().unix(),
      nps: nps,
      refer: {
        name: " ",
      },
      comment: "",
      timestamp: moment().unix(),
      bill: billInfo.bill || null,
      amount: billInfo.amount || null,
      table: billInfo.table || null,
      remarks: "",
      feedback_form: form.id,
      feedback_taker: {
        pin: dash,
      },
      feedbacker: feedbacker,
      answers: answers,
    };

    if (form.feedback_id) {
      data["feedback_id"] = form.feedback_id;
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/feedbacks/apiv2/feedback-api/`,
        data
      )
      .then(() => {
        message.success("SUCCESS");
        this.setState({ loading: false, submitted: true });
      })
      .catch(() => {
        message.error("ERROR, Try again.");
        this.setState({ loading: false });
      });
  };

  renderQuestionScreens = () => {
    const { screens, submitted, loading, forceShowSubmit, nps } = this.state;
    const { store, form, npsAction } = this.props;

    const AButton = this.AButton(form.screen_message);

    if (screens) {
      return (
        <div className={styles.formContainer}>
          {submitted ? (
            <Result
              status="success"
              style={{ color: form.screen_message.text_color }}
              title={
                form.screen_message.thankyou_message ? (
                  // <Typography.Title
                  //   level={2}
                  //   style={{ color: form.screen_message.text_color }}
                  //   dangerouslySetInnerHTML={{__html: form.screen_message.thankyou_message}}
                  // />
                  <h2 
                    style={{ color: form.screen_message.text_color }}
                    dangerouslySetInnerHTML={{__html: form.screen_message.thankyou_message}}
                  />
                ) : (
                  <Typography.Title
                    level={2}
                    style={{ color: form.screen_message.text_color }}
                  >
                    Thank you for your valuable feedback. It means lot to us.
                  </Typography.Title>
                )
              }
              subTitle={
                <div className={styles.banner}>
                  <div>
                    <div className="center">
                      {npsAction && npsAction.redirect_url && 
                      (nps >= npsAction.nps_range[0] && nps <= npsAction.nps_range[1]) 
                      ? <p>You will be redirected to <a href={`${npsAction.redirect_url}`}>{npsAction.redirect_url}</a></p> :""}
                      {this.handleRedirect(npsAction)}
                    </div>
                    <AButton
                      type="primary"
                      size="large"
                      onClick={() => {
                        const { match } = this.props;

                        var routeObj = {
                          pathname: `/response/${match.params.slug}`,
                        };
                        this.props.history.replace(routeObj);
                        window.location.reload();
                      }}
                    >
                      Start a New Response
                    </AButton>
                  </div>
                  {store && store.store_logo ? (
                    <>
                      <p style={{ color: form.screen_message.text_color }}>
                        Powered by
                      </p>
                      <a href="https://fellafeeds.com">
                        <img
                          className={styles.logo}
                          src={store.store_logo}
                          alt={store.name}
                        />
                      </a>
                    </>
                  ) : (
                    <>
                      <p style={{ color: form.screen_message.text_color }}>
                        Powered by
                      </p>
                      <a href="https://fellafeeds.com">
                        <img
                          className={styles.logo}
                          src={logo}
                          alt="Fellafeeds"
                        />
                      </a>
                    </>
                  )}
                </div>
              }
            />
          ) : (
            <Form onFinish={this.handleSubmit} ref={this.formRef}>
              {screens.map((screen, index) => {
                if (screen.display) {
                  return (
                    <>
                      <Bounce bottom key={index}>
                        <div
                          key={screen.id}
                          className={styles.questionContainer}
                          style={{
                            color: `${form.screen_message.text_color} !important`,
                          }}
                        >
                          <div
                            style={{ color: `#fff !important` }}
                            className={styles.questionTitle}
                          >
                            <Typography.Title
                              level={1}
                              style={{
                                color: form.screen_message.text_color,
                              }}
                            >
                              {index + 1}. {screen.title}
                            </Typography.Title>
                          </div>
                        </div>
                        <div
                          className={styles.answerContainer}
                          style={{
                            color: `${form.screen_message.text_color} !important`,
                          }}
                        >
                          {screen.if_option
                            ? this.renderOptions(screen)
                            : this.renderNonOptions(screen)}
                        </div>
                        {index === screens.length - 1 || forceShowSubmit ? (
                          <div className={styles.submit}>
                            <Form.Item>
                              <AButton
                                type="primary"
                                size="large"
                                htmlType="submit"
                                loading={loading}
                              >
                                Submit
                              </AButton>
                            </Form.Item>
                          </div>
                        ) : (
                          ""
                        )}
                      </Bounce>

                      <div className={styles.footerLeft}>
                        <div className={styles.banner}>
                          {store && store.store_logo ? (
                            <>
                              <p
                                style={{
                                  color: form.screen_message.text_color,
                                }}
                              >
                                Powered by
                              </p>
                              <a href="https://fellafeeds.com">
                                <img
                                  className={styles.logo}
                                  src={store.store_logo}
                                  alt={store.name}
                                />
                              </a>
                            </>
                          ) : (
                            <>
                              <p
                                style={{
                                  color: form.screen_message.text_color,
                                }}
                              >
                                Powered by
                              </p>
                              <a href="https://fellafeeds.com">
                                <img
                                  className={styles.logo}
                                  src={logo}
                                  alt="Fellafeeds"
                                />
                              </a>
                            </>
                          )}
                        </div>
                      </div>

                      <div className={styles.footerRight}>
                        <div>
                          <h4 style={{ color: form.screen_message.text_color }}>
                            Question {index + 1} of {screens.length}
                          </h4>
                        </div>
                        <AButton
                          type="primary"
                          size="middle"
                          onClick={() => this.handlePrevious(screen.id, null)}
                          disabled={index <= 0}
                          htmlType="submit"
                        >
                          <UpOutlined />
                        </AButton>
                        <AButton
                          type="primary"
                          size="middle"
                          onClick={() => this.handleNext(screen.id, null)}
                          disabled={index >= screens.length - 1}
                          htmlType="submit"
                        >
                          <DownOutlined />
                        </AButton>
                      </div>
                    </>
                  );
                }
              })}
            </Form>
          )}
        </div>
      );
    }
  };

  handleRedirect = (npsAction) => {
    const {nps} = this.state;
    if(npsAction && npsAction.redirect_url && (nps >= npsAction.nps_range[0] && nps <= npsAction.nps_range[1]) ){
      setTimeout(() => {
        window.location.href = npsAction.redirect_url
      }, 3000)
    }
  }

  render() {
    const { form, store, npsAction } = this.props;
    const { welcome } = this.state;

    const SButton = styled.button`
      padding: 15px;
      background-color: ${form.screen_message.button_bg_color};
      color: ${form.screen_message.button_text_color};
      cursor: pointer;
      border: 1px solid ${form.screen_message.button_bg_color};
      font-size: 24px;
      letter-spacing: 1px;
      border-radius: 3px;
      font-weight: 700;
      &:hover {
        border: 1px solid ${form.screen_message.button_bg_color};
      }
      &:active {
        border: 1px solid ${form.screen_message.button_bg_color};
      }
      &:focus {
        border: 1px solid ${form.screen_message.button_bg_color};
      }
    `;
    // const getDominantColor = (image) => {
    //   if (image) {
    //     let img = new Image();
    //     img.crossOrigin = "Anonymous";
    //     let imgUrl = image;
    //     const cors = "https://cors-anywhere.herokuapp.com/";
    //     img.src = cors + imgUrl;

    //     let colorThief = new ColorThief();
    //     img.addEventListener(
    //       "load",
    //       () => {
    //         const c = colorThief.getColor(img);
    //         // this.setState({
    //         //     dominantColor: `rgba(${c[0]}, ${c[1]}, ${c[2]}, 0.67)`
    //         // })
    //         return `rgba(${c[0]}, ${c[1]}, ${c[2]}, 0.67)`;
    //       },
    //       false
    //     );
    //   } else {
    //     // this.setState({
    //     //     dominantColor: `rgba(255,255,255,0.67)`
    //     // })
    //     return `rgba(255,255,255,0.67)`;
    //   }
    // };

    return welcome ? (
      <div
        className={styles.container}
        style={{
          backgroundColor: form.screen_message.background_color,
          color: form.screen_message.text_color,
          backgroundImage: form.screen_message.background_image
            ? `url("${form.screen_message.background_image}")`
            : "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className={styles.flexCenterCol}
          style={{
            backgroundColor: form.screen_message.darken_image
              ? "#00000061"
              : "",
            height: "100%",
            width: "100%",
          }}
        >
          <div className={styles.welcomeScreenTitle}>
            {form.screen_message.brand_logo ? (
              <div className="flex-center">
                <img
                  className={styles.brandLogo}
                  src={form.screen_message.brand_logo}
                  alt="brand logo"
                />
              </div>
            ) : (
              ""
            )}
            {form.screen_message.welcome_message ? (
              <Typography.Title
                style={{ color: form.screen_message.text_color }}
                level={2}
              >
                {form.screen_message.welcome_message}
              </Typography.Title>
            ) : (
              <>
                <Typography.Title
                  style={{ color: form.screen_message.text_color }}
                  level={2}
                >
                  Please fill this survey to help us serve you better.
                </Typography.Title>
              </>
            )}
            {/* </div>*/}
            <div className={styles.welcomeScreenButton}>
              {/* <Button
                                size="large"
                                type="primary"
                                onClick={() => this.setState({ welcome: false })}
                                className={styles.button}
                            >
                                Get started
                            </Button> */}
              <SButton onClick={() => this.setState({ welcome: false })}>
                {form.screen_message.start_button_text || "Get Started"}
              </SButton>
            </div>
          </div>

          <div className={styles.banner}>
            {store && store.store_logo ? (
              <>
                <p style={{ color: form.screen_message.text_color }}>
                  Powered by
                </p>
                <a href="https://fellafeeds.com">
                  <img
                    className={styles.logo}
                    src={store.store_logo}
                    alt={store.name}
                  />
                </a>
              </>
            ) : (
              <>
                <p style={{ color: form.screen_message.text_color }}>
                  Powered by
                </p>
                <a href="https://fellafeeds.com">
                  <img className={styles.logo} src={logo} alt="Fellafeeds" />
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div
        className={styles.container}
        style={{
          backgroundColor: form.screen_message.background_color,
          color: form.screen_message.text_color,
          backgroundImage: form.screen_message.background_image
            ? `url("${form.screen_message.background_image}")`
            : "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className={styles.flexCenterCol}
          style={{
            backgroundColor: form.screen_message.darken_image
              ? "#00000061"
              : "",
            height: "100%",
            width: "100%",
          }}
        >
          {this.renderQuestionScreens()}
        </div>
      </div>
    );
  }
}

export default withRouter(OneQuestionAtTime);
